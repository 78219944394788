/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'hourglass': {
    width: 16,
    height: 16,
    viewBox: '0 0 503.996 503.996',
    data: '<path pid="0" d="M382.02 452.812h-.084v-71.86c0-24.308-15.584-54.652-35.144-69.076l-75.108-55.184v-9.384l75.104-55.184c19.564-14.424 35.144-44.768 35.144-69.076v-71.86h.084c13.028 0 23.54-10.596 23.54-23.624v-3.94C405.556 10.596 395.044 0 382.016 0h-259.94c-13.028 0-23.64 10.596-23.64 23.624v3.936c0 13.028 10.604 23.624 23.632 23.624l.108 71.86c0 24.312 15.304 54.636 34.908 69.028l75.228 55.276v9.296l-75.288 55.28c-19.596 14.392-34.964 44.716-34.964 69.028v71.86h.016c-13.028 0-23.64 10.596-23.64 23.624v3.936c0 13.028 10.612 23.624 23.64 23.624H382.02c13.028 0 23.54-10.596 23.54-23.624v-3.936c0-13.028-10.512-23.624-23.54-23.624zm-15.836.004h-19.688v-61.76c0-17.948-11.264-40.356-25.72-51.004l-63.912-47.136a7.837 7.837 0 00-9.32-.012l-64.248 47.18c-14.472 10.632-25.8 33.02-25.8 50.972v61.756h-19.688v-71.86c0-19.18 13.124-44.976 28.584-56.332l78.468-57.636a7.86 7.86 0 003.196-6.348v-17.272c0-2.508-1.148-4.864-3.164-6.348l-78.52-57.636c-15.464-11.356-28.564-37.152-28.564-56.332v-71.86h228.376v71.86c0 19.188-13.296 45.016-28.744 56.404l-78.18 57.54c-2.012 1.484-3.328 3.832-3.328 6.336v17.344c0 2.5 1.188 4.852 3.2 6.336l78.312 57.544c15.44 11.388 28.74 37.216 28.74 56.404v71.86z"/><path pid="1" d="M323.64 155.152a7.876 7.876 0 00-7.484-5.416l-128.192-.04a7.881 7.881 0 00-7.484 5.42 7.882 7.882 0 002.824 8.804l64.252 47.176a7.856 7.856 0 004.66 1.528c1.64 0 3.284-.512 4.672-1.54l63.944-47.136a7.87 7.87 0 002.808-8.796z"/>'
  }
})
