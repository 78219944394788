/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'csv-download': {
    width: 16,
    height: 16,
    viewBox: '0 0 48 48',
    data: '<g data-name="Layer 23"><path pid="0" d="M21 31H8a3 3 0 01-3-3v-6a3 3 0 013-3h13a3 3 0 013 3v6a3 3 0 01-3 3zM8 21a1 1 0 00-1 1v6a1 1 0 001 1h13a1 1 0 001-1v-6a1 1 0 00-1-1zM25 16H15a1 1 0 01-.92-.62 1 1 0 01.21-1.09l10-10a1 1 0 011.09-.21A1 1 0 0126 5v10a1 1 0 01-1 1zm-7.59-2H24V7.41z"/><path pid="1" d="M10.78 27.29a2.49 2.49 0 01-2.55-2.55 2.48 2.48 0 012.55-2.55 2.61 2.61 0 011.22.31 2.32 2.32 0 01.88.85l-.84.48a1.25 1.25 0 00-.51-.51 1.51 1.51 0 00-.76-.19 1.52 1.52 0 00-1.15.45 1.75 1.75 0 000 2.32 1.51 1.51 0 001.15.44 1.61 1.61 0 00.76-.18 1.2 1.2 0 00.51-.51l.84.48A2.32 2.32 0 0112 27a2.44 2.44 0 01-1.22.29zM15.15 27.29a2.16 2.16 0 01-1.22-.29 1.69 1.69 0 01-.7-.88l.82-.48a1.12 1.12 0 001.13.74 1 1 0 00.6-.14.47.47 0 00.19-.37.45.45 0 00-.24-.41 3.53 3.53 0 00-.82-.31 5.15 5.15 0 01-.56-.19 2.68 2.68 0 01-.45-.27 1.07 1.07 0 01-.35-.41 1.38 1.38 0 01-.12-.59 1.32 1.32 0 01.47-1.06 1.76 1.76 0 011.14-.4 1.92 1.92 0 011.05.29 2 2 0 01.7.81l-.81.47a1 1 0 00-.94-.63.73.73 0 00-.47.14.44.44 0 00-.17.35.45.45 0 00.19.38 2.77 2.77 0 00.74.31l.34.11.31.11a1.72 1.72 0 01.31.16 1.51 1.51 0 01.25.18.93.93 0 01.21.25 1.42 1.42 0 01.13.3 1.6 1.6 0 01.05.39 1.31 1.31 0 01-.49 1.08 2 2 0 01-1.29.36zM18.63 27.19L17 22.29h1l1.2 3.77 1.19-3.77h1.05l-1.65 4.9z"/><path pid="2" d="M40 43h-6.57a1 1 0 010-2H40a1 1 0 001-1V7a1 1 0 00-1-1H25.41L16 15.41V20a1 1 0 01-2 0v-5a1 1 0 01.29-.71l10-10A1 1 0 0125 4h15a3 3 0 013 3v33a3 3 0 01-3 3z"/><path pid="3" d="M30.57 43H17a3 3 0 01-3-3V30a1 1 0 012 0v10a1 1 0 001 1h13.57a1 1 0 010 2z"/><path pid="4" d="M32 45a1 1 0 01-.81-.42l-5-7A1 1 0 0127 36h1v-5a1 1 0 011-1h6a1 1 0 011 1v5h1a1 1 0 01.81 1.58l-5 7A1 1 0 0132 45zm-3.06-7L32 42.28 35.06 38H35a1 1 0 01-1-1v-5h-4v5a1 1 0 01-1 1z"/></g>'
  }
})
