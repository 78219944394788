/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'stock': {
    width: 16,
    height: 16,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M139.13 0v233.739H384V0H139.13zm189.218 100.174H194.783V66.783h133.565v33.391zM5.565 267.13V512h233.739V267.13H5.565zm178.087 100.174H61.217v-33.391h122.435v33.391zM272.696 267.13V512h233.739V267.13H272.696zm178.087 100.174H328.348v-33.391h122.435v33.391z"/>'
  }
})
