/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'wallet': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.232 1.198A3 3 0 0120 4.098V5a3 3 0 013 3v12a3 3 0 01-3 3H4a3 3 0 01-3-3V7.54a3 3 0 012.232-2.9l13-3.442zM9.686 5l7.058-1.868A1 1 0 0118 4.098V5H9.686zM20 7H4a1 1 0 00-1 1v12a1 1 0 001 1h16a1 1 0 001-1v-3h-3a3 3 0 010-6h3V8a1 1 0 00-1-1zm1 6h-3a1 1 0 100 2h3v-2z" _fill="#293644"/>'
  }
})
