/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'check-circle': {
    width: 667,
    height: 667,
    viewBox: '0 0 667 667',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M666.667 333.333c0 184.094-149.24 333.334-333.334 333.334C149.238 666.667 0 517.427 0 333.333 0 149.238 149.238 0 333.333 0c184.094 0 333.334 149.238 333.334 333.333zm-198.99-101.011c9.763 9.763 9.763 25.593 0 35.355L301.01 434.343c-9.763 9.764-25.59 9.764-35.354 0l-66.667-66.666c-9.763-9.764-9.763-25.59 0-35.354 9.763-9.763 25.592-9.763 35.355 0l48.989 48.987 74.494-74.493 74.496-74.495c9.764-9.763 25.59-9.763 35.354 0z" _fill="#000"/>'
  }
})
