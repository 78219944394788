/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'safe': {
    width: 46,
    height: 46,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M14.222 13.767a2.5 2.5 0 11-3.598-3.475 2.5 2.5 0 013.598 3.475z"/><path pid="1" d="M5.125 2A1.875 1.875 0 003.25 3.875V5.75h-.625a.625.625 0 000 1.25h.625v4.375h-.625a.625.625 0 100 1.25h.625V17h-.625a.625.625 0 100 1.25h.625v1.875A1.875 1.875 0 005.125 22h15A1.875 1.875 0 0022 20.125V3.875A1.875 1.875 0 0020.125 2h-15zM8.92 7.58l1.363 1.363a3.754 3.754 0 014.345 0L15.99 7.58a.625.625 0 01.884.885L15.51 9.828a3.749 3.749 0 010 4.345l1.363 1.362a.625.625 0 01-.884.885l-1.363-1.363a3.753 3.753 0 01-4.345 0L8.92 16.42a.627.627 0 01-.885-.885l1.363-1.363a3.754 3.754 0 010-4.345L8.035 8.466a.626.626 0 11.885-.885zm10.58 2.545v3.75a.624.624 0 11-1.25 0v-3.75a.625.625 0 111.25 0z"/>'
  }
})
