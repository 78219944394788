/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'detailed-order': {
    width: 512,
    height: 512,
    viewBox: '0 0 512.063 512.063',
    data: '<path pid="0" d="M326.281 30.125h-40.125C286.156 13.487 272.669 0 256.031 0s-30.125 13.487-30.125 30.125h-40.125v60.25h140.5z"/><path pid="1" d="M356.281 60.313v60.062h-200.5V60.313H90.406v451.75h331.25V60.313zM180.656 436.75h-30v-30h30zm0-60.25h-30v-30h30zm0-60.25h-30v-30h30zm0-60.25h-30v-30h30zm0-60.25h-30v-30h30zm175.625 241H210.906v-30h145.375zm0-60.25H210.906v-30h145.375zm0-60.25H210.906v-30h145.375zm0-60.25H210.906v-30h145.375zm0-60.25H210.906v-30h145.375z"/>'
  }
})
