/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'doughnut': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M21 10a7 7 0 00-7-7v7h7z" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M11 21a8 8 0 008-8h-8V5a8 8 0 100 16z" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
