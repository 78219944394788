/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'basket': {
    width: 46,
    height: 46,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M8.486 3.051a.6.6 0 111.03.617L6.46 8.76h11.08l-3.056-5.092a.6.6 0 111.03-.617L18.94 8.76H21a.6.6 0 01.6.6v2.4a.6.6 0 01-.6.6h-.6v6a2.4 2.4 0 01-2.4 2.4H6a2.4 2.4 0 01-2.4-2.4v-6H3a.6.6 0 01-.6-.6v-2.4a.6.6 0 01.6-.6h2.06l3.425-5.71h.002zM6.6 14.16a.6.6 0 00-1.2 0v3.6a.6.6 0 101.2 0v-3.6zm3 0a.6.6 0 00-1.2 0v3.6a.6.6 0 101.2 0v-3.6zm3 0a.6.6 0 00-1.2 0v3.6a.6.6 0 101.2 0v-3.6zm3 0a.6.6 0 00-1.2 0v3.6a.6.6 0 101.2 0v-3.6zm3 0a.6.6 0 00-1.2 0v3.6a.6.6 0 101.2 0v-3.6z"/>'
  }
})
