/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'invoice': {
    width: 403,
    height: 403,
    viewBox: '-47 0 403 403.1',
    data: '<path pid="0" d="M259.352 33l-50-33-49.5 32.898a10.15 10.15 0 01-11.102 0L99.352.102l-50 33A9.973 9.973 0 0138.25 33L.05 7v396.102h308.598V7L270.45 33a9.967 9.967 0 01-11.097 0zm-24 319.602h-162c-5.524 0-10-4.48-10-10 0-5.524 4.476-10 10-10h162c5.52 0 10 4.476 10 10 0 5.52-4.48 10-10 10zm-81-45c-64.618 0-117-52.383-117-117 0-64.618 52.382-117 117-117 64.617 0 117 52.382 117 117-.086 64.582-52.418 116.914-117 117zm0 0"/><path pid="1" d="M154.352 93.602c-53.575 0-97 43.425-97 97 0 53.57 43.425 97 97 97 53.57 0 97-43.43 97-97-.051-53.551-43.45-96.95-97-97zm30.097 75.199c5.524 0 10 4.476 10 10 0 5.523-4.476 10-10 10h-57.597v1.8c0 1.297.097 2.5.199 3.7h51.597c5.524 0 10 4.476 10 10 0 5.523-4.476 10-10 10H133.25a45.503 45.503 0 007.398 9.597 47.328 47.328 0 0050.403 10.704c5.137-1.989 10.91.562 12.898 5.699s-.562 10.91-5.699 12.898c-24.8 9.567-52.914 3.606-71.7-15.199a68.155 68.155 0 01-15.402-23.8h-11c-5.52 0-10-4.477-10-10 0-5.524 4.48-10 10-10h6.801c-.097-1.2-.097-2.5-.097-3.7v-1.8h-6.704c-5.52 0-10-4.477-10-10 0-5.524 4.48-10 10-10h10.204a66.196 66.196 0 0116.097-25.7c18.809-18.773 46.895-24.727 71.7-15.2 5.136 1.99 7.69 7.763 5.703 12.9-1.989 5.136-7.766 7.687-12.903 5.698-22.445-8.636-47.8.965-58.898 22.301zm0 0"/>'
  }
})
