/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'shop': {
    width: 512,
    height: 512,
    viewBox: '0 0 480.037 480.037',
    data: '<path pid="0" d="M313.037 329.019v151h-65v-142a8 8 0 00-16 0v142h-65v-151c0-13.233 10.766-24 24-24h98c13.234 0 24 10.766 24 24zm131.995-75.47c-27.739 11.362-60.124 6.76-83.778-13.202a8.05 8.05 0 00-10.427-.004c-30.46 25.722-75.08 25.733-105.552.023a8.092 8.092 0 00-10.475 0c-30.486 25.721-75.131 25.692-105.569-.035a8.052 8.052 0 00-10.433.003c-23.663 19.982-56.049 24.583-83.747 13.215-5.255-2.157-11.013 1.723-11.013 7.403V440.02c0 22.091 17.909 40 40 40h87v-151c0-22.056 17.944-40 40-40h98c22.056 0 40 17.944 40 40v151h87c22.091 0 40-17.909 40-40V260.955c-.001-5.676-5.753-9.557-11.006-7.406zM60.037 136.019a8 8 0 01-7.983-8.532c.275-4.252 3.996-7.468 8.257-7.468h404.792c5.757 0 9.63-5.898 7.34-11.181L427.377 4.839a7.994 7.994 0 00-7.34-4.82h-360c-3.19 0-6.07 1.89-7.34 4.82l-52 120c-.933 2.12-.66-1.102-.66 52.81.06 36.39 29.67 65.99 66 65.99 22.69 0 42.756-11.542 54.638-29.074a4.018 4.018 0 016.683.001c26.271 38.78 83.101 38.694 109.34.028a4.017 4.017 0 016.679 0c26.259 38.697 83.049 38.717 109.321-.001 1.591-2.345 5.037-2.427 6.624-.08 12.023 17.774 32.474 29.414 55.568 29.12 36.284-.461 65.147-30.72 65.147-67.007v-32.608a8 8 0 00-8-8h-412z"/>'
  }
})
