/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'warning-circle': {
    width: 667,
    height: 667,
    viewBox: '0 0 667 667',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M333.336 666.67c184.096 0 333.334-149.239 333.334-333.335C666.67 149.239 517.432 0 333.336 0 149.238 0 0 149.239 0 333.335 0 517.431 149.238 666.67 333.336 666.67zm40.266-471.461l114.334 210.174C506.713 437.016 483.938 477 447.164 477H218.492c-36.804 0-59.541-40.016-40.762-71.617l114.329-210.174c18.394-30.966 63.173-30.925 81.543 0zm-59.737 224.898c0 10.454 8.51 18.964 18.965 18.964s18.965-8.51 18.965-18.964c0-10.455-8.51-18.965-18.965-18.965s-18.965 8.51-18.965 18.965zm18.965-37.929c10.455 0 18.965-8.51 18.965-18.965v-94.822c0-10.454-8.51-18.964-18.965-18.964s-18.965 8.51-18.965 18.964v94.822c0 10.455 8.51 18.965 18.965 18.965z" _fill="#000"/>'
  }
})
