/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'give-gift': {
    width: 16,
    height: 16,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M29.9 16.5c-.2-.3-.5-.5-.9-.5-2.2 0-4.3 1-5.6 2.8l-.9 1.2c-1.1 1.3-2.8 2-4.5 2h-3c-.6 0-1-.4-1-1s.4-1 1-1h1.9c1.6 0 3.1-1.3 3.1-2.9V17c0-.5-.5-1-1-1h-6.1c-3.6 0-6.5 1.6-8.1 4.2l-2.7 4.2c-.2.3-.2.7 0 1l3 5c.1.2.4.4.6.5h.2c.2 0 .4-.1.6-.2 3.8-2.5 8.2-3.8 12.7-3.8 3.3 0 6.3-1.8 7.9-4.7l2.7-4.8c.2-.2.2-.6.1-.9zM11 11v3.2c.6-.1 1.3-.2 1.9-.2H19c1.3 0 2.4.8 2.8 2h.2c.6 0 1-.4 1-1v-4c.6 0 1-.4 1-1V6c0-.6-.4-1-1-1h-1.2c.1-.3.2-.6.2-1 0-1.7-1.3-3-3-3-.8 0-1.5.3-2 .8-.5-.5-1.2-.8-2-.8-1.7 0-3 1.3-3 3 0 .4.1.7.2 1H11c-.6 0-1 .4-1 1v4c0 .6.4 1 1 1zm7-7c0-.6.4-1 1-1s1 .4 1 1-.4 1-1 1h-1V4zm-3-1c.6 0 1 .4 1 1v1h-1c-.6 0-1-.4-1-1s.4-1 1-1z"/>'
  }
})
