/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'chart-horizontal': {
    width: 800,
    height: 800,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M3 4.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C3.76 3 4.04 3 4.6 3h8.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C15 3.76 15 4.04 15 4.6v.8c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437C14.24 7 13.96 7 13.4 7H4.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C3 6.24 3 5.96 3 5.4v-.8zM3 11.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C3.76 10 4.04 10 4.6 10h14.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C21 10.76 21 11.04 21 11.6v.8c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437C20.24 14 19.96 14 19.4 14H4.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C3 13.24 3 12.96 3 12.4v-.8zM3 18.6c0-.56 0-.84.109-1.054a1 1 0 01.437-.437C3.76 17 4.04 17 4.6 17h4.8c.56 0 .84 0 1.054.109a1 1 0 01.437.437C11 17.76 11 18.04 11 18.6v.8c0 .56 0 .84-.109 1.054a1 1 0 01-.437.437C10.24 21 9.96 21 9.4 21H4.6c-.56 0-.84 0-1.054-.109a1 1 0 01-.437-.437C3 20.24 3 19.96 3 19.4v-.8z" _stroke="#000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
