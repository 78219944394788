/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'loyalty-program': {
    width: 512,
    height: 512,
    viewBox: '0 0 509.581 509.581',
    data: '<path pid="0" d="M182.682 369.782l-38.367-66.455-9.3-3.385v209.639h31.74l73.26-77.567v-83.099z"/><path pid="1" d="M375.986 101.694l-10.85-61.532h-62.481L254.791 0l-47.864 40.162h-62.481l-10.85 61.532-47.864 40.163 31.241 54.11-10.85 61.533 58.714 21.37 31.241 54.11 58.714-21.37 58.714 21.37 31.241-54.11 58.714-21.37-10.85-61.533 31.241-54.11zm-148.438 115.82l-39.146-45.671 22.777-19.523 20.854 24.329 68.757-51.567 18 24z"/><path pid="2" d="M365.266 303.327l-38.367 66.455-56.884-20.704v82.869l73.548 77.634h31.452V299.779z"/>'
  }
})
