/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'euro': {
    width: 48,
    height: 48,
    viewBox: '0 0 20 20',
    data: '<path pid="0" d="M12 15c-1.63 0-3.06-.79-3.98-2H12l1-2H7.1c-.06-.32-.1-.66-.1-1s.04-.68.1-1H12l1-2H8.02c.92-1.21 2.35-2 3.98-2 1.38 0 2.63.56 3.54 1.46l1.41-1.41A6.976 6.976 0 0012 3C9.21 3 6.81 4.64 5.68 7H3L2 9h3.08c-.05.33-.08.66-.08 1s.03.67.08 1H3l-1 2h3.68a6.999 6.999 0 0011.27 1.95l-1.41-1.41c-.91.9-2.16 1.46-3.54 1.46z"/>'
  }
})
