/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'waiting-circle': {
    width: 667,
    height: 667,
    viewBox: '0 0 667 667',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M666.67 333.335c0 184.096-149.238 333.335-333.334 333.335C149.238 666.67 0 517.431 0 333.335 0 149.239 149.238 0 333.336 0 517.432 0 666.67 149.239 666.67 333.335zM333.5 503c94.164 0 170.5-76.336 170.5-170.5S427.664 162 333.5 162 163 238.336 163 332.5 239.336 503 333.5 503z" _fill="#000"/><rect pid="1" x="298" y="238" width="44" height="141" rx="22" _fill="#000"/><rect pid="2" x="451" y="335" width="44" height="141" rx="22" transform="rotate(90 451 335)" _fill="#000"/>'
  }
})
