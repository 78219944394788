/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'gift': {
    width: 16,
    height: 16,
    viewBox: '0 0 264.047 264.047',
    data: '<path pid="0" d="M229.048 146.136c-1.407-1.406-3.093-2.089-5.083-2.089h-79.942v120h72.421c8.283 0 14.579-7.108 14.579-15.392v-97.323c0-1.989-.568-3.789-1.975-5.196zM120.023 144.047H40.747c-4.143 0-7.724 3.144-7.724 7.286v97.322c0 8.283 6.962 15.392 15.245 15.392h71.755v-120zM120.023 66.047H48.38c-8.297 0-15.356 6.726-15.356 15.021v34.957c0 8.296 7.06 15.022 15.356 15.022h71.644v-65zM216.333 66.047h-72.31v65h72.31c8.297 0 14.69-6.726 14.69-15.022V81.068c0-8.296-6.393-15.021-14.69-15.021zM172.258 6.204C166.94 2.088 161.631 0 156.478 0c-10.301 0-18.388 7.643-24.104 22.73C126.658 7.643 118.572 0 108.271 0c-5.153 0-10.463 2.088-15.78 6.204-10.12 7.834-11.195 15.99-10.316 21.453 3.295 20.47 40.313 34.526 47.7 37.137a7.487 7.487 0 002.498.429h.002c.839 0 1.684-.141 2.498-.429 7.387-2.61 44.405-16.668 47.7-37.137.879-5.463-.196-13.618-10.315-21.453zm-56.803 35.642c-16.092-8.854-18.207-14.925-18.472-16.574-.338-2.11 1.239-4.535 4.689-7.206 2.591-2.006 4.872-3.066 6.599-3.066 3.209 0 8.097 5.118 12.193 19.483 1.099 3.852 1.953 7.736 2.605 11.222a132.99 132.99 0 01-7.614-3.859zm52.309-16.575c-1.042 6.497-13.751 14.669-26.14 20.505C144.563 30.401 150.249 15 156.478 15c1.727 0 4.008 1.06 6.598 3.066 3.449 2.67 5.027 5.094 4.688 7.205z"/>'
  }
})
