/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'cards': {
    width: 800,
    height: 800,
    viewBox: '0 0 256 256',
    data: '<path pid="0" d="M200.008 88v112a16.018 16.018 0 01-16 16h-144a16.018 16.018 0 01-16-16V88a16.018 16.018 0 0116-16h144a16.018 16.018 0 0116 16zm16-48h-152a8 8 0 000 16h152v120a8 8 0 0016 0V56a16.018 16.018 0 00-16-16z"/>'
  }
})
