/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'new-order': {
    width: 682.667,
    height: 682.667,
    viewBox: '0 0 512 512',
    data: '<path pid="0" d="M147.6 14.5c-4.7 1.7-9.4 5.9-11.2 10.2-1.2 2.7-1.4 19.6-1.4 96.8V215h36V50h201v39.4c0 25.5.4 40.5 1.1 42.3 1.1 3.1 5.3 7.8 8.4 9.6 1.4.8 14.5 1.4 43 1.7l41 .5.3 159.3.2 159.2H135v11c0 7 .5 12.2 1.4 14.3 1.7 4.3 7.6 9.3 12.3 10.6 5.3 1.5 334.3 1.5 339.6 0 5.2-1.4 11.4-7.2 12.7-11.7.6-2.5 1-65.3 1-184.3V121.3l-2.2-3.9c-1.3-2.1-24.6-26-51.8-53.1-42-41.7-50.1-49.3-53.5-50.2-2.8-.8-40.6-1.1-123.5-1.1-98.9.1-120.2.3-123.4 1.5z"/><path pid="1" d="M210 151v18h122v-36H210v18zM12 220c-2.3 2.3-2.6 5.8-.6 8.6 1.3 1.7 3.1 2 17.7 2.4l16.3.5 15.8 66c8.6 36.3 16.9 71.1 18.4 77.4 1.9 8.3 3.3 12.1 5 13.8L87 391l61.6-.2 61.6-.3 1.9-2.4c2.6-3.1 2.4-5.7-.6-8.6l-2.4-2.5h-57.6c-31.6 0-57.5-.2-57.5-.4s-1.1-6.3-2.5-13.6-2.5-13.7-2.5-14.1c0-.5 29.4-1 65.3-1.1l65.2-.3 12.2-49c6.7-27 12.2-49.8 12.3-50.8 0-1.6-5-1.7-90.4-1.7H63.3L60 233.7c-1.7-6.7-3.9-13-4.8-14-1.3-1.5-3.7-1.7-21.4-1.7-18.5 0-19.9.1-21.8 2zM287.5 239.2c-1 3.1-8.5 32.5-8.5 33.6 0 .9 17.3 1.2 74 1.2h74v-36h-69.5c-54.8 0-69.7.3-70 1.2zM256.1 359.2c-2.3 9-4.4 17.1-4.7 18-.5 1.7 4.1 1.8 87.5 1.8H427v-36H260.3l-4.2 16.2zM97 408.2c-5.7 3.9-7.5 7.2-7.5 13.8 0 6.6 1.8 9.9 7.6 13.8 4.4 3.1 12.9 2.8 17.7-.7 9.1-6.5 8.7-20.5-.8-26.8-4.6-3.2-12.4-3.2-17-.1zM182.2 407.7c-7.5 3.7-10.5 14.4-6.2 22.1 4.4 7.8 15.1 10.7 22.1 5.9 5.8-3.9 8.1-8.3 7.7-14.8-.5-6.5-3.2-10.9-8.2-13.3-4.3-2-11.2-2-15.4.1z"/>'
  }
})
